import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../types';
import { RootState } from '../store';

export interface SliceState {
  user: User | null;
}

const initialState: SliceState = {
  user: null
};

export const slice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    }
  }
});

const selectUser = (store: RootState) => store.slice.user;

const selectUserSelector = createSelector([selectUser], (user) => ({
  user
}));

export const sliceSelectors = {
  // \n
  selectUserSelector
};

// Action creators are generated for each case reducer function
export const {
  // \n
  setUser
} = slice.actions;

export default slice.reducer;

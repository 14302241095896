import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define interfaces
interface CartItem {
  itemId: string;
  itemName: string;
  quantity: number;
  price: number;
}

interface CartState {
  items: {
    [restaurantId: string]: {
      restaurantName: string;
      items: CartItem[];
    };
  };
}

// Load initial state from localStorage
const loadCartState = (): CartState => {
  const savedCart = localStorage.getItem('cart');
  return savedCart ? JSON.parse(savedCart) : { items: {} };
};

const initialState: CartState = loadCartState();

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<{ restaurantId: string; restaurantName: string; item: CartItem }>) => {
      const { restaurantId, restaurantName, item } = action.payload;

      if (!state.items[restaurantId]) {
        state.items[restaurantId] = {
          restaurantName,
          items: [item]
        };
      } else {
        const existingItem = state.items[restaurantId].items.find((i) => i.itemId === item.itemId);
        if (existingItem) {
          existingItem.quantity += item.quantity;
        } else {
          state.items[restaurantId].items.push(item);
        }
      }
      localStorage.setItem('cart', JSON.stringify(state)); // Persist after update
    },

    incrementQuantity: (state, action: PayloadAction<{ restaurantId: string; itemId: string }>) => {
      const { restaurantId, itemId } = action.payload;
      const item = state.items[restaurantId]?.items.find((i) => i.itemId === itemId);
      if (item) {
        item.quantity += 1;
      }
      localStorage.setItem('cart', JSON.stringify(state)); // Persist after update
    },

    decrementQuantity: (state, action: PayloadAction<{ restaurantId: string; itemId: string }>) => {
      const { restaurantId, itemId } = action.payload;
      const item = state.items[restaurantId]?.items.find((i) => i.itemId === itemId);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      } else {
        state.items[restaurantId].items = state.items[restaurantId].items.filter((i) => i.itemId !== itemId);
        if (state.items[restaurantId].items.length === 0) {
          delete state.items[restaurantId];
        }
      }
      localStorage.setItem('cart', JSON.stringify(state)); // Persist after update
    },

    removeRestaurantFromCart: (state, action: PayloadAction<string>) => {
      delete state.items[action.payload];
      localStorage.setItem('cart', JSON.stringify(state)); // Persist after update
    },

    clearCart: (state) => {
      state.items = {};
      localStorage.removeItem('cart');
    }
  }
});

export const { addToCart, incrementQuantity, decrementQuantity, removeRestaurantFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;

import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';

const InvalidPage: React.FC = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center'
        }}
      >
        <Typography variant="h4" gutterBottom>
          Oops! This page does not exist.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Seems like you ended up on a page that does not exist.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/" sx={{ mt: 2 }}>
          Return to Home Page
        </Button>
      </Box>
    </Box>
  );
};

export default InvalidPage;

import { Box, CircularProgress } from '@mui/material';

const ReactLazyLoading = () => {
  return (
    <Box
      sx={{
        position: 'absolute', // Constrain to parent container
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent overlay
        zIndex: 10, // Ensure it’s above content but below global overlays
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default ReactLazyLoading;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from './AuthProvider';
import ReactLazyLoading from './components/ReactLazyLoading';

export interface AppAuthProps {
  children: JSX.Element | JSX.Element[];
}

const AppAuth = ({ children }: AppAuthProps) => {
  const { loading, user, authenticating } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !authenticating && !user) {
      navigate('/');
    }
  }, [loading, authenticating, user, navigate]);

  if (loading || authenticating) {
    // Show loading while checking auth or redirecting
    return <ReactLazyLoading />;
  }

  return children;
};

export default AppAuth;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getIdToken } from 'firebase/auth'; // Import getIdToken to retrieve token
import { auth } from './AuthProvider'; // Import auth from your provider
import { GetOrderRequest, RegisterOrderRequest, RegisterRestaurantRequest, RestaurantInfo } from './types';
import { ApiUser, GetAllUsersResponse, RegisterUserRequest } from './types/user';

const { VITE_SERVICE_HOST } = import.meta.env;

// Define a service using a base URL and expected endpoints
export const service = createApi({
  tagTypes: [
    //n
    'restaurant'
  ],
  reducerPath: 'service',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_SERVICE_HOST,
    prepareHeaders: async (headers) => {
      // Retrieve the token from Firebase
      const currentUser = auth.currentUser;

      if (currentUser) {
        const idToken = await getIdToken(currentUser, /* forceRefresh */ false);
        headers.set('Authorization', `Bearer ${idToken}`);
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    // <ReturnObject, SendObject>
    /*
      Restaurant endpoints
    */
    getRestuarantByEmail: builder.query<RestaurantInfo, string>({
      providesTags: ['restaurant'],
      query: (email) => ({
        url: '/getRestaurantByEmail',
        method: 'POST',
        body: {
          email
        }
      })
    }),
    getRestaurantById: builder.query<RestaurantInfo, string>({
      providesTags: ['restaurant'],
      query: (restaurantId) => ({
        url: `/restaurant/${restaurantId}`,
        method: 'GET'
      })
    }),
    getAllRestaurants: builder.query<RestaurantInfo[], void>({
      query: () => ({
        url: `/getAllRestaurants`,
        method: 'GET'
      })
    }),
    registerRestaurant: builder.mutation<void, RegisterRestaurantRequest>({
      invalidatesTags: ['restaurant'],
      query: (restaurantInfo) => ({
        method: 'POST',
        url: '/registerRestaurant',
        body: restaurantInfo
      })
    }),
    /*
      User endpoints
    */
    registerUser: builder.mutation<void, RegisterUserRequest>({
      query: (userInfo) => ({
        method: 'POST',
        url: '/registerUser',
        body: userInfo
      })
    }),
    getCurrentUser: builder.query<ApiUser, void>({
      query: () => ({
        url: '/getCurrentUser',
        method: 'GET'
      })
    }),
    getAllUsers: builder.query<void, GetAllUsersResponse[]>({
      query: () => ({
        url: '/getAllUsers',
        method: 'GET'
      })
    }),
    // deleteUser: builder.mutation<void, string>({
    //   query: (userId) => ({
    //     url: `/user/deleteUser/${userId}`,
    //     method: 'DELETE'
    //   })
    // }),
    /*
      Order endpoints
    */
    getOrder: builder.query<GetOrderRequest, void>({
      query: () => ({
        url: '/getOrder',
        method: 'GET'
      })
    }),
    getAllOrders: builder.query<GetOrderRequest[], void>({
      query: () => ({
        url: '/getAllOrders',
        method: 'GET'
      })
    }),
    registerOrder: builder.mutation<void, RegisterOrderRequest>({
      query: (orderInfo) => ({
        method: 'POST',
        url: '/registerOrder',
        body: orderInfo
      })
    }),
    deleteOrder: builder.mutation<void, string>({
      query: (orderId) => ({
        method: 'DELETE',
        url: `/deleteOrder/${orderId}`
      })
    }),
    createPaymentIntent: builder.mutation<{ clientSecret: string; paymentIntentId: string }, { restaurantId: string; quantity: number }>({
      query: ({ restaurantId, quantity }) => ({
        method: 'POST',
        url: '/registerOrder/create-payment-intent',
        body: { restaurantId, quantity }
      })
    }),
    getIntentStatus: builder.query<{ status: string }, string>({
      query: (paymentIntentId) => ({
        method: 'GET',
        url: `/getOrder/intentStatus/${paymentIntentId}`
      })
    })
  })
});

export const {
  // \n
  useGetAllRestaurantsQuery,
  useRegisterRestaurantMutation,
  useCreatePaymentIntentMutation,
  useLazyGetCurrentUserQuery,
  useGetOrderQuery,
  useGetAllOrdersQuery,
  useRegisterUserMutation,
  useGetRestaurantByIdQuery
} = service;
